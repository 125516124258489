#contact {
  background-image: linear-gradient(
    135deg,
    $primary-color 0%,
    $secondary-color 100%
  );
  -webkit-clip-path: polygon(0 15vh, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 15vh, 100% 0, 100% 100%, 0 100%);
  padding: 15rem 0 10rem 0;
  margin-top: -10rem;
  margin-bottom: -1px;
  color: $white-color;

  @include respond(tab-land) {
    padding: 10rem 0;
    clip-path: none;
    margin-top: 0;
    -webkit-clip-path: none;
  }

  .contact-wrapper {
    margin-top: 3.2rem;
    padding: 0 2rem;
    backface-visibility: hidden;

    & input,
    & button,
    & textarea,
    & label {
      font-size: $default-font-size;
    }

    @include respond(phone) {
      padding: 0;
    }

    &__text {
      margin-bottom: 2.5rem;
    }

    &__text,
    a {
      font-size: 2.4rem;

      @include respond(phone) {
        font-size: 2rem;
      }
    }

    &__form {
      font-weight: bold;
      margin-bottom: 1.8rem;
      font-size: $mid-font-size;
      display: flex;
      flex-direction: column;
    }

    &__short-fields {
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      @include respond(phone) {
        flex-direction: column;
      }
    }

    &__field {
      flex-grow: 1;
      padding: 0.8rem;
      @include respond(phone) {
        padding: 0.8rem 0;
      }

      & textarea,
      input {
        padding: 0.8rem;
        outline: unset;
        color: $primary-color;
        font-weight: bold;
        border-left: $primary-color solid 1px;
        border-top: $primary-color solid 1px;
        border-right: $secondary-color solid 1px;
        border-bottom: $secondary-color solid 1px;
      }
    }

    &__label {
      display: block;
      padding-bottom: 0.5rem;
    }

    &__input {
      width: 100%;
    }

    &__button {
      width: fit-content;
      align-self: center;
      background-color: transparent;
    }
  }
}
